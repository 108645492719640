import { createRoot } from 'react-dom/client';
import { createElement } from '../Utils/createElement';
class BaseElement extends HTMLElement {
    constructor() {
        super(...arguments);
        this.element = null;
    }
    connectedCallback() {
        const attributes = this.getAttributeList();
        let props = attributes;
        if (attributes.hasOwnProperty('json-props')) {
            props = Object.assign(props, JSON.parse(attributes['json-props']));
            delete props['json-props'];
        }
        if (this.element) {
            createElement(this, this.element, props);
        }
    }
    disconnectedCallback() {
        // @ts-ignore
        const rootElement = createRoot(this.element);
        rootElement.unmount();
    }
    getAttributeList() {
        const result = {};
        Object.values(this.attributes).map((attribute) => {
            let key = attribute.name;
            if (attribute.name !== 'json-props') {
                key = key.replace(/-./g, x => x[1].toUpperCase());
            }
            return { key, value: attribute.value };
        }).forEach((el) => result[el.key] = el.value);
        return result;
    }
}
export default BaseElement;

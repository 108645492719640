import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import pThrottle from 'p-throttle';
import { UserProvider } from '../Context/UserContext';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1,
            gcTime: 0,
        },
    },
});
const persister = createSyncStoragePersister({
    storage: window.localStorage,
});
const config = {
    url: 'https://features.infra.sitzkrieger.com/api/frontend',
    // todo: work on
    clientKey: 'default:production.c9117da7da33ca9ed362969542abb116116fe0ce3b0bf50f06288748',
    refreshInterval: 60 * 10,
    appName: 'sitzkrieger-web',
};
const client = new UnleashClient(config);
const throttle = pThrottle({
    limit: 1,
    interval: 1000,
});
const DeferredUnleashClient = (props) => {
    React.useEffect(() => {
        const asyncProcess = throttle(async () => {
            // still emits errors for every additional react instance that uses it, but for now that's actually fine as its completely async
            client.start();
        });
        asyncProcess();
    }, []);
    return <FlagProvider unleashClient={client} startClient={false}>
        {props.children}
    </FlagProvider>;
};
export function createElement(root, element, props) {
    const ReactElement = () => React.createElement(element, props);
    const rootElement = createRoot(root);
    rootElement.render(<React.StrictMode>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <UserProvider>
                <DeferredUnleashClient>
                    <ReactElement />
                </DeferredUnleashClient>
            </UserProvider>
        </PersistQueryClientProvider>
    </React.StrictMode>);
}
